// 默认颜色
:root {
  --color: #4754ff;
  --color-active: rgba(71, 84, 255, 0.8);
  --color-hover: rgba(71, 84, 255, .1);
  --color-hint: #4754ff;
  //页面线条颜色
  --border-deep: #DDDDDD;
  --border-middle: #DCE0E5;
  --border-light: #fcfcfc;
  //页面文字颜色
  --font-title: #000;
  --font-normal: #666;
  --font-tips: #999;
  --font-high: #4754ff;
  // 用于时间、普通按钮不可点状态、提示文字、灰色icon等
  --disabled: #b3b3b3;
  //背景色
  --bg-color: #fDDDDDD;
  //其他相关配色
  --btn-color: #ffb919;
  --orangey-red: #F43005;
  --orange: #ffb67a;
  --orange-hover: #cc9e38;
  --orange-red: rgba(255, 99, 80, 0.9);
  --font-green: #4db814;

}

.theme-dc3848 {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1);
}

.theme-eb382f {
  --color: #eb382f;
  --color-active: #d1322a;
  --color-hover: rgba(235, 56, 47, .1);
}

.theme-fb6321 {
  --color: #fb6321;
  --color-active: #fc824d;
  --color-hover: rgba(251, 99, 33, .1);
}

.theme-b25724 {
  --color: #b25724;
  --color-active: #994b1f;
  --color-hover: rgba(178, 87, 36, .1);
}

.theme-dbaf58 {
  --color: #dbaf58;
  --color-active: #c29b4e;
  --color-hover: rgba(219, 175, 88, .1);
}

.theme-fab414 {
  --color: #fab414;
  --color-active: #e0a212;
  --color-hover: rgba(250, 180, 20, .1);
}

.theme-23982d {
  --color: #23982d;
  --color-active: #4fac57;
  --color-hover: rgba(35, 152, 45, .1);
}

.theme-f566d3 {
  --color: #f566d3;
  --color-active: #db5cbe;
  --color-hover: rgba(245, 102, 211, .1);
}

.theme-c38cfa {
  --color: #c38cfa;
  --color-active: #b07ee0;
  --color-hover: rgba(195, 140, 250, .1);
}

.theme-1c69e6 {
  --color: #1c69e6;
  --color-active: #185dcc;
  --color-hover: rgba(28, 105, 230, .1);
}

.theme-20ade5 {
  --color: #20ade5;
  --color-active: #4dbdea;
  --color-hover: rgba(32, 173, 229, .1);
}

.theme-0ec2b9 {
  --color: #0ec2b9;
  --color-active: #10E1D7;
  --color-hover: rgba(14, 194, 185, .1);
}

.theme-282828 {
  --color: #282828;
  --color-active: #494949;
  --color-hover: rgba(40, 40, 40, .1);
}

.theme-2d6285 {
  --color: #2d6285;
  --color-active: #285473;
  --color-hover: rgba(45, 98, 133, .1);
}

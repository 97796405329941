.experience-add-box {
  width: 100%;
  background: #fff;
}

.experience-add-body {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  background: url("../../assets/images/map.png") no-repeat center / 530px 260px;
  text-align: center;

  h2 {
    padding-top: 100px;
    font-size: 24px;
    color: var(--color);
  }

  .experience-add {
    padding-top: 22px;

    .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 240px;
      padding: 0;
      margin: 0 20px;
    }

    .search-size-max {
      height: 40px;
      line-height: 40px;
    }

    .dis-inb:last-child {
      .search-size-max {
        width: 260px;

      }

      .txt {
        padding-right: 20px;
      }
    }
  }
}

.experience-list-box {
  width: 1200px;
  margin: 0 auto;
  font-size: 0;
  padding-top: 16px;

  .null-tips-box {
    height: 300px;
    text-align: center;
    padding-top: 120px;
    font-size: 14px;
    color: var(--font-normal);
  }
}

.experience-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 16px;
  background: #fff;
  border: 1px solid var(--border-deep);
  width: calc(50% - 8px);
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 0;
  cursor: pointer;

  &:nth-child(2n) {
    margin-right: 0;
  }

  h2 {
    font-size: 16px;
    color: var(--font-title);
    margin-bottom: 12px;
    cursor: pointer;
    min-height: 40px;

    &:hover {
      color: var(--color);
    }

    .iconfont {
      margin-right: 4px;
    }
  }

  .img-box {
    width: 160px;
    height: 160px;
    display: inline-block;
    vertical-align: top;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .txt-box {
    display: inline-block;
    width: calc(100% - 170px);
    vertical-align: top;
    padding: 12px 0 0 24px;

    dt {
      font-size: 14px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1.3;
      padding-bottom: 12px;
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }

    dd {
      font-size: 12px;
      color: var(--font-tips);
      line-height: 1.3;
      padding-bottom: 12px;
    }
  }

  .experience-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 149px;
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px var(--color);
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    color: var(--color);
    cursor: pointer;
  }
}

.experience-detail-box {
  width: 1200px;
  margin: 0 auto;
}

.experience-detail-head {
  margin: 20px 0;
  font-size: 0;
  height: 420px;

  .experience-info {
    display: inline-block;
    vertical-align: top;
    width: 450px;
    height: 100%;
    background: #fff;
    padding: 18px 0 26px;
    text-align: center;

    h4 {
      font-size: 14px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1em;
      padding-bottom: 24px;
    }

    .code-box {
      display: inline-block;
      width: 180px;
      height: 180px;
      border: 1px solid var(--border-deep);
      margin-bottom: 32px;
      padding: 4px;
    }

    p {
      font-size: 12px;
      color: var(--font-title);
      padding-bottom: 8px;
      line-height: 1em;
    }

    .experience-btn {
      margin-top: 26px;
      display: inline-block;
      width: 149px;
      height: 34px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px var(--color);
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      color: var(--color);
      cursor: pointer;
    }
  }

  .experience-banner {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 450px);
    height: 420px;
    background: #fff;
    position: relative;

    .ant-carousel {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

      .slick-slide {
        text-align: center;
        height: 420px;
        line-height: 420px;
        overflow: hidden;
      }

      .slick-dots {
        li {
          overflow: hidden;
          border-radius: 4px;

          &.slick-active button {
            background: var(--color);
          }
        }
      }

    }

    .img-box {
      width: 100%;
      height: 420px;
      position: relative;

      .img-wrap {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: none;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}


.dis-inb {
  display: inline-block;
}

.pop-experience {

  .experience-body {
    text-align: center;

    h4 {
      font-size: 14px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1em;
      padding-bottom: 10px;
      text-align: center;
    }

    .code {
      display: inline-block;
      width: 180px;
      height: 180px;
      border: 1px solid var(--border-deep);
      padding: 4px;
      margin-bottom: 18px;
    }

    p {
      font-size: 12px;
      line-height: 1em;
      color: var(--font-title);
      padding-bottom: 10px;
      text-align: center;
    }
  }

}

.experience-detail-body {
  background: #fff;

  .experience-detail-body-title {
    font-size: 14px;
    font-weight: bold;
    padding: 16px;
    border-bottom: 1px solid var(--border-deep);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 2px;
      height: 16px;
      background: var(--font-title);
      transform: translateY(-50%);
    }
  }
}

.video-box {
  padding: 16px 0;
  text-align: center;

  .video-wrap {
    display: inline-block;
    width: 600px;
    height: 400px;
  }

  video {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.experience-introduce-box {
  padding: 24px;
}

.experience-page-footer {
  text-align: center;
}

.floor-middle-box {
  max-width: 1200px;
  margin: 0 auto;
}

.floor-grail-layout {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .layout-little-box {
    width: 200px;

    .foods-floor-box-wrap{
      height: 100%;
      display: grid;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      margin-bottom: 0 !important;
    }
    .goods-card{
      box-sizing: border-box;
      padding: 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .img-box{
        padding: 0;
      }
      
      .info-box{
        padding: 0;
        margin-top: 12px;
      }
    }
  }

  .layout-large-box {
    width: 990px;
  }
}


.switcher-box {
  position: fixed;
  left: 50%;
  bottom: 12px;
  width: 540px;
  height: 60px;
  z-index: 3;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(104, 104, 104, .86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.50);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 44px;
  color: #fff;
  border-radius: 100px;

  .close-btn {
    position: absolute;
    left: 24px;
    cursor: pointer;
  }

  h2 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    padding-left: 12px;
  }

  .btn-box {
    font-size: 0;
    flex: 1;
    text-align: right;
  }

  .switch-btn {
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: rgb(110, 206, 252);
    border-radius: 100px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border: 0 none;
    margin-right: 24px;
    cursor: pointer;

    &:nth-child(2) {
      background: rgb(255, 109, 109);
      margin-right: 10px;
    }

    .iconfont {
      font-size: 14px;
      padding-right: 8px;
    }
  }
}

.switcher-right-box {
  position: fixed;
  right: 0;
  bottom: 36px;
  z-index: 3;
  background: rgba(104, 104, 104, .86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.50);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: #fff;
  padding: 16px 12px 16px 14px;
  border-radius: 26px 0 0 26px;

  .close-btn {
    display: none;
  }

  h2 {
    display: none;
  }

  .btn-box {
    font-size: 0;
    text-align: right;
  }

  .switch-btn {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: rgb(110, 206, 252);
    border-radius: 50%;
    font-size: 0;
    color: #fff;
    text-align: center;
    border: 0 none;
    cursor: pointer;
    margin-bottom: 18px;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #A1A1A1;
      position: absolute;
      left: 0;
      bottom: -7px;
    }

    &:nth-child(1) {
      background: rgba(0, 0, 0, 0.20);
    }

    &:nth-child(3) {
      background: rgb(255, 109, 109);
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }

    .iconfont {
      font-size: 14px;
      line-height: 32px;
    }
  }
}

.pop-experience-tips {
  text-align: left;

  p {
    font-size: 14px;
    color: var(--font-normal);
    line-height: 1.5;
    margin-bottom: 10px;

    .iconfont {
      margin-right: 6px;
    }
  }

  .btn {
    width: 100%;
    height: 36px;
    line-height: 36px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 18px;
    background-color: var(--color);
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);

    }
  }
}

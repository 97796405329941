.FooterCompanyInfo{
    .footerTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-box{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .footetLogo{
            margin-right: 24px;
        }
        .footerTel{
            color: #121212;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 137.5% */
            letter-spacing: 0.5px;
        }
        .right-box{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .shopItem{
            width: 36px;
            height: 36px;
            margin-left: 20px;
        }
        
    }
    
    .copyrightWrap{
        border-top: 1px solid #C2C7D1;
        padding: 12px 0;
        color: #595959;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.5px;
        text-align: center;

        a {
            display: inline;
        }
    }
}
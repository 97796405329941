.user-page-box {
  padding: 16px;
  background: #fff;
//   display: flex;
  height: 100%;

  .breadcrumb-block-box {
    margin-left: -220px;
    margin-bottom: 12px;
  }

  .left-box {
    flex: 1;
  }

  .user-info-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-box {
      padding: 10px;
      background: #F8F8F8;
      display: flex;

      .user-card {
        flex: 1;
      }

      .account-box {
        width: 40%;
        display: flex;
        justify-content: space-between;
        .account-blance{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          padding-right: 12px;
        }
        .blance-title{
          color: rgba(0, 0, 0, 0.60);
          font-family: PingFang SC;
          font-size: 14px;
        }
        .blance-value{
          margin-left: 4px;
          color: #000;
          font-family: PingFang SC;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .order-statistic-box {
      height: 84px;

      .statistic-box-card {
        height: 100%;
      }
    }


  }

  .info-list-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-list-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px;
    }

    .info-list-card-main {
      .null-tips-box {
        padding: 40px 0;
      }

      .list-card-box {
        padding: 20px;
        display: flex;
        align-content: center;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        &:last-child {
          border-bottom: 0 none;
        }

        .img-box {
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          margin-right: 8px;

          img {
            max-height: 100%;
            max-width: 100%;
          }

        }

        .main-box {
          flex: 1;
          width: 0;
          line-height: 1;
          font-size: 12px;
          margin-right: 24px;

          .text-title {
            color: var(--font-title);
          }

          .text-tips {
            margin-top: 8px;
            color: var(--font-tips);
          }
        }

        .btn-box {
          .btn {
            width: 64px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid #dcdcdc;
            text-align: center;
            line-height: 22px;
            font-size: 12px;
            color: var(--font-title);
            background: #fff;

            &:hover {
              color: #fff;
              background: var(--color);
            }
          }
        }

        .tips-box {
          font-size: 12px;
          color: var(--font-tips);
        }
      }
    }
  }

  .info-data-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px;

    .info-data-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px;
    }

    .info-data-card-body {
      padding: 16px;
      font-size: 12px;
      color: var(--font-normal);

      .row-card {
        margin-bottom: 10px;
      }

      .iconfont {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        font-size: 12px;
      }
    }
  }

  .right-box {
    width: 290px;
    margin-left: 16px;
  }
}

.account-details-page-box {
  padding: 16px;
  background: #fff;
  height: 100%;
  .breadcrumb-block-box {
    margin-left: -220px;
    margin-bottom: 12px;
  }

  .account-details-page-main-head {
    margin-bottom: 8px;

    .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px;
    }

    .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px;
    }
  }

  .account-details-page-main-body {
    .statistics-box {
      height: 96px;
      background: #f5f5f5;
      border: 1px solid var(--border-deep);
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 16px;

      .statistics-item {
        flex: 1;
        text-align: center;

        .number {
          font-size: 20px;
          font-weight: bold;
        }

        .title {
          font-size: 12px;
          color: var(--font-tips);
        }
      }
    }
  }

  .account-details-page-main-foot {
    text-align: center;
    margin-top: 16px;
  }
}

.pick-up-list-page {
  .account-details-page-main {
    .account-details-page-main-head {
      .input-wrap {
        .ant-input-affix-wrapper {
          margin-right: 30px;
        }
      }
    }
  }
  .account-details-page-main-body {
    .cont-table {
      .tr-txt {
        cursor: pointer;
        &.active {
          background-color: pink;
        }
      }
    }
  }
}


.title-line-box {
  padding-left: 12px;
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep);

  &:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: var(--font-title);
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.account-page-box {
  padding: 16px;
  background: #fff;
  .breadcrumb-block-box {
    margin-left: -220px;
    margin-bottom: 12px;
  }

  .account-page-body {

    .title-line-box {
      margin-bottom: 16px;
    }

    .form-item-box {
      width: 400px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}


.notices-details-page-box {
  margin-top: 14px;
  background: #fff;

  .notices-details-page-body {
    padding: 16px;
  }

  .attachment-list {
    a {
      display: flex;
    }

    .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .svg-icon {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      flex: 1;
    }
  }
}

.staff-page-box {
  margin-top: 14px;
  background: #fff;

  .staff-page-body {
    padding: 16px;

    .add-btn-box {
      padding-bottom: 16px;

      .link-btn {
        border: 0 none;
        background: transparent;
        color: var(--color);
        font-size: 14px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle;

        &[disabled] {
          color: var(--disabled);
        }

        .iconfont {
          margin-right: 4px;
          font-size: 14px;
        }
      }

      .tips {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: var(--font-tips);
      }

    }
  }
}

.collect-list-box {
  margin-top: 14px;
  background: #fff;
  min-height: 100%;

  .collect-list-body {
    padding: 16px 0;


    .filter-sheet-wrap {
      padding: 0;
    }

    .sort-sheet-box {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-deep);

      .ope-wrap {
        .btn {
          margin-left: 12px;
          display: inline-block;
          vertical-align: middle;
          width: auto;
          padding: 0 8px;
          font-size: 12px;
          color: var(--font-title);
          background: #f5f5f5;
          border: 1px solid var(--border-deep);
        }

        .link-btn {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          color: var(--font-normal);
          margin-left: 12px;
          cursor: pointer;

          &:hover {
            color: var(--color);
          }

          .iconfont {
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }

          .icon-check-s {
            color: var(--color);
          }
        }
      }
    }
  }
}

.help-detail-page-box {
  display: flex;
  width: 100%;

  .notices-details-page-box {
    flex: 1;
  }
}

.view-card-box {
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--border-deep);
  padding: 10px 0;

  .title-box {
    width: 90px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--font-normal);

    .txt {
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        background: var(--border-deep);
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  .main-box {
    padding-left: 1em;
    font-size: 14px;
    color: var(--font-title);
    flex: 1;

    &.hint {
      color: var(--font-high);
    }

    .img-box {
      display: inline-block;
      vertical-align: top;
      width: 100px;
      height: 100px;
      border: 1px solid var(--border-deep);
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 98px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

}

.invoice-list-page {
    padding: 16px;
  .breadcrumb-block-box {
    margin-left: -220px;
    margin-bottom: 12px;
  }

  .invoice-list-page-main {
    background-color: #fff;

    .tabs-box {
      .tab {
        display: inline-block;
        vertical-align: middle;
        margin: 0 14px;
        font-size: 14px;
        color: var(--font-normal);
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--color);
        }

        &.active {
          color: var(--color);
          border-color: var(--color);
        }
      }
    }
  }

  .invoice-list-table-wrap {
    padding: 16px;

    .cont-table {
      min-height: 320px;
      margin-bottom: 24px;
    }

    .ant-empty {
      margin-top: 32px;
    }
  }
}

.invoice-detail-des {
  padding: 8px 12px;
  background: #FAFAFA;

  .head {
    font-size: 16px;
    font-weight: 700;

    .label {
      color: var(--font-normal);
    }

    .value {
      color: var(--font-title);
    }
  }

  .row {
    margin-top: 6px;

    .label {
      font-size: 14px;
      color: var(--font-normal);
    }

    .value {
      display: inline-block;
      min-width: 130px;
      margin-right: 24px;
      color: var(--font-title);
    }
  }
}

.invoice-detail-attachment {
  padding: 24px;
}

// 优惠券
.coupon-list-page {
  position: relative;
  margin: 16px 0;
  .breadcrumb-block-box {
    margin-left: -220px;
  }

  .coupon-center-btn {
    position: absolute;
    right: 16px;
    top: 50px;
    color: #FF860C;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 100px;
    background: rgba(255, 134, 12, 0.10);
    z-index: 2;
  }

  .coupon-list-tabs {
    margin-top: 8px;
    padding: 0 14px;
    background-color: #fff;

    .coupon-list-tabpane {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-height: 400px;

      .coupon-list-card:nth-child(4n) {
        margin-right: 0;
      }

      .ant-empty {
        display: block;
        margin: 16px auto;
      }
    }
    .ant-tabs-extra-content {
        margin-right: 20px;
    }
  }

  .ant-pagination {
    padding: 24px 0;
    background-color: #fff;
    text-align: center;
  }
}
.coupon-wrap {
  .coupon-list-card {
    width: 220px;
    height: 280px;
    margin-right: 29px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    background-color: #F7F8FA;

    .info-box {
      position: relative;
      height: 120px;
      padding: 16px;
      color: #fff;
      text-align: center;
      background: linear-gradient(110deg, #F23434 0%, #FC846A 100%);
      .price {
        font-size: 36px;
        line-height: 1;

        &:first-letter {
          font-size: 24px;
        }

        .target {
          font-size: 16px;
          margin-left: 8px;
        }
      }
      .name {
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0;
      }

      .time {
        font-size: 14px;
        opacity: .85;
      }
      .whiteCircle{
        transform: translate(-10px, -4px);
      }

      .tag {
        position: absolute;
        right: 0px;
        top: 0;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 0 0 0 6px;
        color: #89660B;
        background-color: #FFDD66;
      }
      .tag_remain {
        position: absolute;
        top: 2px;
        left: 4px;
      }

      .status-icon {
        position: absolute;
        right: 8px;
        bottom: 8px;
      }
    }

    .intro-box {
      margin-top: -1px;
      border: 1px solid var(--border-deep);
      border-radius: 0 4px 4px 0;
      height: 160px;
      padding:  16px 0;
      
    }

    .explain-box {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin: 16px 0;
      max-height: 122px;
      min-height: 74px;
      cursor: pointer;

      .label {
        color: var(--font-title);
      }

      .introduce {
        flex: 1;
        color: var(--font-normal);
      }

      .iconfont {
        font-size: 14px;
      }
    }

    .coupon-btn {
      width: 100px;
      height: 36px;
      line-height: 20px;
      padding: 8px 0;
      position: absolute;
      transition: all 0.3s;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid var(--color);
      color: var(--color);
      text-align: center;
      cursor: pointer;
      user-select: none;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
    }

  }

  .coupon-list-card-used,
  .coupon-list-card-expires {
    .info-box {
      position: relative;
      padding: 16px;
      color: #000;
      background: linear-gradient(110deg, #E3E3E3 0%, #E3E3E3 100%);
      .tag {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.45);
      }
    }

  }
}

.coupon-center-page {


  .headline {
    text-align: center;
    height: 114px;
    line-height: 114px;
    background-color: #fff;
    .icon-title-1{
      width: 316px;
      height: 40px;
    }
  }

  .coupon-center-main {
    width: 1200px;
    margin: 8px auto;
    padding: 24px;
    background-color: #fff;

    .ant-pagination {
      padding-top: 24px;
      text-align: center;
    }
  }

  .coupon-center-list {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(5, 1fr);
  }

  .coupon-center-card {
    width: 374px;
    display: flex;
    margin-bottom: 16px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, .2);

    &:nth-child(3n) {
      margin-right: 0;
    }

    .price-box {
      width: 120px;
      height: 108px;
      padding-top: 32px;
      text-align: center;
      color: #fff;
      background: radial-gradient(circle at left center, transparent 3px, #FF3838 0) left top / 4px 12px repeat-y,
      linear-gradient(to left, #FF6969, #FF3838) 3px / 118px no-repeat;

      .money {
        font-size: 24px;
        line-height: 1;

        &:first-letter {
          font-size: 12px;
        }
      }

      .target {
        line-height: 1;
        font-size: 12px;
        margin-top: 8px;
      }
    }

    .info-box {
      display: flex;
      width: 255px;
      height: 108px;
      padding: 16px 0;
      border-radius: 0 2px 2px 0;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(227, 227, 227, 1);
    }

    .intro-box {
      width: 162px;
      padding: 0 12px 0 16px;
      border-right: 1px dashed var(--border-deep);

      .tag {
        display: inline-block;
        padding: 0 8px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 49px;
        background-color: var(--color-hint);
        color: #fff;
      }

      .name {
        font-size: 14px;
        color: var(--font-title);
        font-weight: 600;
      }

      .time {
        font-size: 12px;
        color: var(--font-tips);
      }
    }

    .status-box {
      width: 92px;
      text-align: center;
    }


    .btn-box {
      text-align: center;

      .btn {
        font-size: 12px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 11px;
        border: 0 none;
        background-color: var(--color-hint);
        color: #fff;
      }
    }
  }
  .coupon-list-card-used,
  .coupon-list-card-used{
    .coupon-btn{
      display: none;
    }
  }
  .coupon-card-use{
    .price-box{
      background: rgba(227, 227, 227, 1);
      color: #000;
      .target{
        opacity: .65;
      }
    }
    .info-box{
      .tag{
        color: var(--font-normal);
        background-color: rgba(227, 227, 227, 1);
      }
    }
  }
}
